import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Veriff } from "@veriff/js-sdk";
import { toast } from "react-toastify";

const StepThree = ({ data }) => {
  const location = useLocation();
  const currentUrl = window.location.origin + location.pathname;

  useEffect(() => {
    const veriff = Veriff({
      apiKey: process.env.REACT_APP_VERIFF_API_KEY,
      parentId: "veriff-root",
      onSession: function (err, response) {
        if (!err) {
          window.location.href = `${response.verification.url}?lang=es-latam`;
        } else {
          toast.error(err.message);
        }
      },
    });

    veriff.setParams({
      vendorData: data.uuid,
      callback: currentUrl,
    });

    veriff.mount({
      formLabel: {
        givenName: "Nombre",
        lastName: "Apellido",
        vendorData: "ID del usuario",
      },
      submitBtnText: "Verificar identidad",
      loadingText: "Por favor, espere...",
    });
  }, [data.uuid, currentUrl]);

  return (
    <div>
      <div className="text-center mb-4">
        <h5>Verificación de identidad</h5>
        <p className="text-muted">
          Por favor, complete el formulario para iniciar la verificación
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <div id="veriff-root" style={{ width: "400px" }}></div>
      </div>
    </div>
  );
};

export default StepThree;
