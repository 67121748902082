import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  CardText,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";

const apiClient = new APIClient();

const QrCodePix = ({
  loading,
  setLoading,
  qrCode,
  countdown,
  ordem,
  resumoCalculo,
  formatCurrency,
  linkData,
  setQrCode,
  setOrdem,
  setCountdown,
  startCountdown,
}) => {
  const handleGerarQrCode = async () => {
    setLoading(true);

    const data = {
      link_id: linkData.id,
      user_id: linkData.user_id,
      moeda_final: "USDT",
      pdv_id: linkData.pdv_id === 0 ? null : linkData.pdv_id,
      total_brl: resumoCalculo.valor_final,
      expiracao: 30 * 60,
    };

    const response = await apiClient.post("/gerar-qr", data);
    if (response.sucesso) {
      toast.success("Código QR gerado com sucesso");
      setQrCode(response.dados.qrcode);
      setOrdem(response.dados);
      startCountdown(data.expiracao);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const renderQRCodeSection = () => {
    if (ordem && ordem.status !== "novo") {
      return (
        <CardBody>
          {" "}
          <div
            className={`rounded-circle p-3 mb-2 ${
              ordem.status === "pago" ? "bg-success" : "bg-danger"
            }`}
            style={{ width: "100px", height: "100px", margin: "0 auto" }}
          >
            <FeatherIcon
              icon={ordem.status === "pago" ? "check-circle" : "x-circle"}
              className="text-white"
              size={70}
            />
          </div>
          <CardText
            tag="h4"
            className={`font-weight-bold mt-4 text-${
              ordem.status === "pago" ? "success" : "danger"
            }`}
          >
            {ordem.status === "pago" ? "Pagamento Recebido" : "Ordem Inválida"}
          </CardText>
          <CardText>
            {ordem.status === "pago"
              ? "O pagamento foi processado com sucesso."
              : "A ordem foi considerada inválida. Por favor, tente novamente."}
          </CardText>
        </CardBody>
      );
    }

    return (
      <CardBody>
        {qrCode !== "" && countdown > 0 ? (
          <>
            <div className="text-center mb-3">
              <p>Este pagamento está sendo realizado para <b>{ordem.comerciante}</b></p>
            </div>
            <Row className="mt-4 d-flex justify-content-center">
              <Col
                xs="12"
                sm="10"
                md="8"
                className="d-flex justify-content-center"
              >
                {qrCode?.includes(".png") ? (
                  <img
                    src={qrCode}
                    width={220}
                    alt="QR Code"
                    style={{ backgroundColor: "white" }}
                    className="p-2"
                  />
                ) : (
                  <QRCode
                    value={qrCode}
                    size={220}
                    style={{ backgroundColor: "white" }}
                    className="p-2"
                  />
                )}
              </Col>
            </Row>
            {!(qrCode === "" || countdown === 0) && (
              <div className="text-center mb-3 mt-3">
                <Button
                  color="light"
                  className="w-xs"
                  onClick={() => {
                    navigator.clipboard.writeText(qrCode);
                    toast.success("Código QR copiado para a área de transferência");
                  }}
                >
                  <FeatherIcon icon="copy" size={20} /> Copiar Código QR
                </Button>
              </div>
            )}
            <div className="text-center mt-3">
              <h5>
                Tempo restante: {Math.floor(countdown / 60)}:
                {String(countdown % 60).padStart(2, "0")}
              </h5>
            </div>
          </>
        ) : null}
      </CardBody>
    );
  };

  return (
    <Col xl={4}>
      <div className="sticky-side-div">
        <Card>
          <CardHeader className="border-bottom-dashed">
            <h5 className="card-title mb-0">Finalizar Compra</h5>
          </CardHeader>

          {(qrCode === "" || countdown === 0) && (
            <CardHeader className="bg-light-subtle border-bottom-dashed">
              <div className="text-center">
                <Button
                  color="primary"
                  className="w-xs"
                  onClick={handleGerarQrCode}
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Gerar Código QR"}
                </Button>
              </div>
            </CardHeader>
          )}
          {renderQRCodeSection()}
          <CardBody className="pt-2">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {resumoCalculo && (
                    <tr className="table-active">
                      <th>Total ({resumoCalculo.moeda_sigla}) :</th>
                      <td className="text-end">
                        <span className="fw-semibold">
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>

        <UncontrolledAlert color="success" className="border-dashed">
          <div className="d-flex align-items-center">
            <div className="ms-2">
              <h5 className="fs-14 text-success fw-semibold">
                ¿Quieres una página de cobro como esta?
              </h5>
              <p className="text-success mb-1">
                Contacta con Nautt Finance para realizar un onboarding gratuito
                y comenzar a recibir pagos en línea.
              </p>
              <a
                href="https://nautt.finance"
                target="_blank"
                rel="noopener noreferrer"
                className="btn p-2 btn-sm btn-link text-success text-uppercase"
              >
                Comenzar Ahora
              </a>
            </div>
          </div>
        </UncontrolledAlert>
      </div>
    </Col>
  );
};

export default QrCodePix;
