import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import GerarOrdemPix from "../pages/GerarOrdemPix";
import Produtos from "../pages/Produtos";
import OrdensPix from "../pages/OrdensPix";
import Usuarios from "../pages/Usuarios";
import NovoUsuario from "../pages/Usuarios/NovoUsuario";
import EditarUsuario from "../pages/Usuarios/EditarUsuario";
import PontosDeVenda from "../pages/PontosDeVenda";
import Depositos from "../pages/Depositos";
import Retiradas from "../pages/Retiradas";
import Recibo from "../pages/Recibo";
import Atendentes from "../pages/Atendentes";
import Configs from "../pages/Configs";
import Informacoes from "../pages/Informacoes";
import LinksPagamento from "../pages/LinksPagamento";
import KybVerifications from "../pages/KybVerifications";
import KycVerifications from "../pages/KycVerifications";
import ApiData from "../pages/ApiData/index";
import Autenticacao from "../pages/ApiData/Autenticacao";
import QrCodePix from "../pages/ApiData/QrCodePix";
import KycWizard from "../pages/KycWizard";
import KybWizard from "../pages/KybWizard";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";

// Outras páginas abertas
import RegisterKyb from "../pages/Authentication/RegisterKyb";
import CheckNautt from "../pages/CheckNautt";

// User Profile
import UserProfile from "../pages/Authentication/UserProfile/user-profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/verificacoes-kyb", component: <KybVerifications /> },
  { path: "/verificacoes-kyc", component: <KycVerifications /> },
  { path: "/ordens-pix", component: <OrdensPix /> },
  { path: "/usuarios", component: <Usuarios /> },
  { path: "/novo-usuario", component: <NovoUsuario /> },
  { path: "/editar-usuario/:user_id", component: <EditarUsuario /> },
  { path: "/pontos-venda", component: <PontosDeVenda /> },
  { path: "/atendentes", component: <Atendentes /> },
  { path: "/produtos", component: <Produtos /> },
  { path: "/links-pagamento", component: <LinksPagamento /> },
  { path: "/depositos", component: <Depositos /> },
  { path: "/retiradas", component: <Retiradas /> },
  { path: "/configs", component: <Configs /> },
  { path: "/informacoes", component: <Informacoes /> },
  { path: "/api-data", component: <ApiData /> },
  { path: "/api/autenticacao", component: <Autenticacao /> },
  { path: "/api/qr-code-pix", component: <QrCodePix /> },
  { path: "/index", component: <DashboardEcommerce /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Other Pages
  { path: "/recibo/:uuid", component: <Recibo /> },
  { path: "/register-kyb", component: <RegisterKyb /> },
  { path: "/kyc-wizard-onboarding", component: <KycWizard /> },
  { path: "/kyc-wizard-onboarding/:uuid", component: <KycWizard /> },
  { path: "/kyc-wizard", component: <KycWizard /> },
  { path: "/kyc-wizard/:uuid", component: <KycWizard /> },
  { path: "/kyb-wizard-onboarding", component: <KybWizard /> },
  { path: "/kyb-wizard-onboarding/:uuid", component: <KybWizard /> },
  { path: "/kyb-wizard", component: <KybWizard /> },
  { path: "/kyb-wizard/:uuid", component: <KybWizard /> },
  { path: "/check-nautt/:link_id", component: <CheckNautt /> },
  { path: "/ponto-de-venda/:pdv_id", component: <CheckNautt /> },
  { path: "/onboarding", component: <CoverSignUp /> },
  { path: "/erro-404", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
