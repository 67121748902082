import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import classnames from "classnames";
import RetiradaCripto from "./RetiradaCripto";
import RetiradaConta from "./RetiradaConta";
import RetiradasNautt from "./RetiradasNautt";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [moeda, setMoeda] = useState(null);
  const [moedas, setMoedas] = useState([]);
  const [token, setToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [amount, setAmount] = useState("");
  const [contaDestino, setContaDestino] = useState("");
  const [carteiraId, setCarteiraId] = useState("");
  const [contas, setContas] = useState([]);
  const [code2fa, setCode2fa] = useState("");
  const [carteiras, setCarteiras] = useState([]);
  const [saldoUsdt, setSaldoUsdt] = useState(0);
  const [isUsdtAmount, setIsUsdtAmount] = useState(false);
  const [userDados, setUserDados] = useState(null);
  const [codAfiliado, setCodAfiliado] = useState("");
  const [codAfiliado1, setCodAfiliado1] = useState("");
  const [userRole, setUserRole] = useState("");

  const [cotacao, setCotacao] = useState(0);
  const [totalMoeda, setTotalMoeda] = useState(0);
  const [hasCotacao, setHasCotacao] = useState(false);

  const inputRefs = useRef([]);

  const toggle = () => {
    setMoeda(null);
    setMoedas([]);
    setToken(null);
    setAmount("");
    setContaDestino("");
    setCarteiraId("");
    setCotacao(0);
    setTotalMoeda(0);
    setHasCotacao(false);
    setIsUsdtAmount(false);
    setSaldoUsdt(0);
    setCodAfiliado("");
    setModal(!modal);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCotacao(0);
      setTotalMoeda(0);
      setHasCotacao(false);
    }
  };
  const handleCodeChange = async (index, value) => {
    if (value.length > 1) value = value[0];
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value !== "") {
      // Move to next input
      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else {
        // If it's the last digit, submit the form
        const fullCode = newCode.join("");
        if (fullCode.length === 6) {
          setCode2fa(fullCode);
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && verificationCode[index] === "" && index > 0) {
      // Move to previous input and clear it
      const newCode = [...verificationCode];
      newCode[index - 1] = "";
      setVerificationCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };
  const getSaldoUsdt = useCallback(async () => {
    const response = await apiClient.get("/widgets", {});
    if (response.sucesso) {
      const dados = response.dados;
      setSaldoUsdt(dados.caixa_usd);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;
      setMoedas(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const fetchTokens = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      const dados = response.dados;
      setTokens(dados);
      // Set USDT token as default
      const usdtToken = dados.find(
        (t) => t.sigla === "USDT" && t.rede.nome === "Arbitrum"
      );
      if (usdtToken) setToken(usdtToken.id);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const fetchContas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/contas-cliente");
    if (response.sucesso) {
      setContas(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const fetchCarteiras = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/carteira-cliente");
    if (response.sucesso) {
      setCarteiras(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const criar = async () => {
    setLoading(true);

    // Validate minimum amounts
    const amountNum = parseFloat(amount);
    if (activeTab === "1" && amountNum < 10) {
      toast.error("El monto mínimo para retiro es USD 10");
      setLoading(false);
      return;
    }
    if (activeTab === "2" && amountNum < 5) {
      toast.error("El monto mínimo para retiro cripto es USD 5");
      setIsUsdtAmount(false);
      setLoading(false);
      return;
    }
    let data;
    if (isUsdtAmount) {
      data = {
        token_id: token,
        amount_usd: amountNum,
        converter: activeTab === "1",
      };
    } else {
      data = {
        token_id: token,
        amount: amountNum,
        converter: activeTab === "1",
      };
    }

    if (activeTab === "1") {
      if (!contaDestino) {
        toast.error("Seleccione una cuenta de destino");
        setLoading(false);
        return;
      }
      if (!moeda) {
        toast.error("Seleccione una moneda");
        setLoading(false);
        return;
      }
      if (!hasCotacao) {
        toast.error("Es necesario cotizar primero");
        setLoading(false);
        return;
      }
      data.conta_destino = contaDestino;
      data.moeda_id = moeda;
    } else {
      if (!carteiraId) {
        toast.error("Seleccione una wallet cripto");
        setLoading(false);
        return;
      }
      data.carteira_id = carteiraId;
    }

    data.code_2fa = code2fa;

    const response = await apiClient.post(`/retiradas`, data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const handleCotar = async () => {
    setLoading(true);
    if (amount === "") {
      toast.error("Ingrese un valor válido");
      setLoading(false);
      return;
    }

    if (!moeda) {
      toast.error("Seleccione una moneda");
      setLoading(false);
      return;
    }

    if (!token) {
      toast.error("Seleccione un token");
      setLoading(false);
      return;
    }

    let data;

    if (isUsdtAmount) {
      data = {
        moeda_id: moeda,
        token_id: token,
        amount_usd: amount,
      };
    } else {
      data = {
        token_id: token,
        amount: amount,
        moeda_id: moeda,
      };
    }

    const response = await apiClient.post("/retiradas/cotacao", data);
    if (response.sucesso) {
      const dados = response.dados;
      setCotacao(dados.cotacao);
      setTotalMoeda(dados.total_moeda);
      setHasCotacao(true);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const handleTransferirNautt = async () => {
    try {
      setLoading(true);
      const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
      
      const response = await apiClient.post("/depositos/transferir", {
        cod_afiliado1: userRole === "admin" ? codAfiliado1 : authUser.cod_afiliado,
        cod_afiliado2: codAfiliado,
        valor_usd: amount,
        code_2fa: code2fa || null,
      });

      if (response.sucesso) {
        toast.success("¡Transferencia realizada con éxito!");
        toggle();
        setRefresh((prev) => !prev);
      } else {
        toast.error(response.mensagem || "Error al realizar la transferencia.");
      }
    } catch (error) {
      toast.error("Error al realizar la transferencia.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
      setUserRole(authUser.role || "");
      setCodAfiliado1(authUser.cod_afiliado || "");
      
      await Promise.all([
        fetchMoedas(),
        fetchTokens(),
        fetchContas(),
        fetchCarteiras(),
        getSaldoUsdt(),
      ]);

      const loggedinUser = await getLoggedinUser();
      setUserDados(loggedinUser);
    };
    if (modal) fetchData();
  }, [modal]);

  // Reset quote when amount or currency changes
  useEffect(() => {
    setCotacao(0);
    setTotalMoeda(0);
    setHasCotacao(false);
  }, [amount, moeda, token]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nuevo Retiro
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Retiro FIAT
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Retiro Cripto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleTab("3");
              }}
            >
              NauttTransfer
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="mt-4">
          <TabPane tabId="1">
            {contas.length === 0 ? (
              <div className="alert alert-warning">
                Necesita registrar una cuenta bancaria primero.{" "}
                <Link to="/profile">Ir al perfil</Link>
              </div>
            ) : (
              <RetiradaConta
                token={token}
                tokens={tokens}
                moeda={moeda}
                setMoeda={setMoeda}
                moedas={moedas}
                amount={amount}
                setAmount={setAmount}
                contaDestino={contaDestino}
                setContaDestino={setContaDestino}
                contas={contas}
                apiClient={apiClient}
                saldoUsdt={saldoUsdt}
                isUsdtAmount={isUsdtAmount}
                setIsUsdtAmount={setIsUsdtAmount}
              />
            )}
          </TabPane>

          <TabPane tabId="2">
            {carteiras.length === 0 ? (
              <div className="alert alert-warning">
                Necesita registrar una wallet cripto primero.{" "}
                <Link to="/profile">Ir al perfil</Link>
              </div>
            ) : (
              <RetiradaCripto
                token={token}
                tokens={tokens}
                amount={amount}
                setAmount={setAmount}
                setToken={setToken}
                carteiraId={carteiraId}
                setCarteiraId={setCarteiraId}
                carteiras={carteiras}
                apiClient={apiClient}
                saldoUsdt={saldoUsdt}
              />
            )}
          </TabPane>

          <TabPane tabId="3">
            <RetiradasNautt
              amount={amount}
              setAmount={setAmount}
              codAfiliado={codAfiliado}
              setCodAfiliado={setCodAfiliado}
              apiClient={apiClient}
              userRole={userRole}
              codAfiliado1={codAfiliado1}
              setCodAfiliado1={setCodAfiliado1}
            />
          </TabPane>
        </TabContent>

        {activeTab === "1" &&
          ((isUsdtAmount && parseFloat(amount) < 100) ||
            (!isUsdtAmount && totalMoeda < 100)) &&
          hasCotacao && (
            <Alert color="warning" className="mt-3">
              Tenga en cuenta que para valores inferiores a 100 USD, el cambio
              puede ser menos favorable de lo habitual.
            </Alert>
          )}

        {activeTab === "1" && hasCotacao && (
          <Alert color="info" className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>Cotización:</strong> {cotacao.toFixed(2)}
              </div>
              <div>
                {!isUsdtAmount ? (
                  <strong>
                    Monto en{" "}
                    {tokens.find((tt) => tt.id === parseInt(token))?.sigla}:{" "}
                  </strong>
                ) : (
                  <strong>
                    Monto en{" "}
                    {moedas.find((m) => m.id === parseInt(moeda))?.sigla}:{" "}
                  </strong>
                )}
                {totalMoeda.toFixed(2)}
              </div>
            </div>
          </Alert>
        )}

        {userDados?.needs_2fa && (
          <div className="mb-4">
            <div className="d-flex justify-content-center gap-2 mb-3">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  className="form-control text-center fw-bold fs-4"
                  style={{
                    width: "28px",
                    height: "45px",
                    padding: "0.375rem",
                  }}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength={1}
                  autoFocus={index === 0}
                />
              ))}
            </div>
            <p className="text-muted text-center">
              Ingrese el código de su aplicación Google Authenticator
            </p>
          </div>
        )}

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-success fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          {activeTab === "3" ? (
            <button
              className="btn btn-success"
              onClick={() => handleTransferirNautt()}
              disabled={loading}
            >
              Transferir
            </button>
          ) : activeTab === "1" && !hasCotacao ? (
            <button
              className="btn btn-success"
              onClick={() => handleCotar()}
              disabled={loading}
            >
              Cotizar
            </button>
          ) : (
            <button
              className="btn btn-success"
              onClick={() => criar()}
              disabled={loading}
            >
              Crear Retiro
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
