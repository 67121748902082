import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

import { Link, useParams } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import logoRecibo from "../../assets/images/logo-recibo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  formatCurrency,
  converterData,
} from "../../Components/FunctionsRepository";

import "./styles.css";

const Recibo = () => {
  const reciboRef = useRef();
  const [reciboDados, setReciboDados] = useState(null);
  const [reciboUuid, setReciboUuid] = useState(null);
  const [erro, setErro] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();

  const handleDownloadPdf = async () => {
    const element = reciboRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      logging: false,
    });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = Math.min(widthRatio, heightRatio) * 0.95; // 95% do tamanho da página

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    pdf.addImage(data, "PNG", marginX, marginY, canvasWidth, canvasHeight);
    pdf.save("recibo.pdf");
  };

  const fetchReciboData = async () => {
    setLoading(true);
    const response = await apiClient.get(`/recibo`, {
      uuid: uuid,
    });
    if (response.sucesso) {
      setReciboDados(response.dados);
      if (response.dados.uuid) setReciboUuid(response.dados.uuid);
      else setReciboUuid(uuid);
    } else setErro(true);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchReciboData();
    };
    fetchData();
  }, [uuid]);

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {reciboDados && (
                  <div className="text-center mb-4 text-white-50">
                    <Button color="success" onClick={handleDownloadPdf}>
                      <i className="mdi mdi-download me-1" /> PDF
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div ref={reciboRef} className="receipt">
                  <div className="logo-recibo">
                    <img src={logoRecibo} alt="Nautt Finance" />
                  </div>

                  {loading ? (
                    <div className="text-center p-5">
                      <Spinner color="success">Cargando...</Spinner>
                    </div>
                  ) : erro ? (
                    <div className="details">
                      <div className="detail-item">
                        <h2>Recibo no encontrado</h2>
                      </div>
                    </div>
                  ) : (
                    reciboDados && (
                      <>
                        <div className="amount-section">
                          {reciboDados.moeda_nome && (
                            <div className="currency">
                              {reciboDados.tipo === "Cripto"
                                ? "Tether USD"
                                : reciboDados.moeda_nome.toUpperCase()}
                            </div>
                          )}
                          <div className="amount">
                            {formatCurrency(
                              reciboDados.valor,
                              reciboDados.moeda_sigla
                            )}
                          </div>
                        </div>

                        <div className="details">
                          {reciboDados.data && (
                            <div className="detail-item">
                              <h2>FECHA | HORA</h2>
                              <p>{converterData(reciboDados.data)}</p>
                            </div>
                          )}
                          {reciboDados.tipo && (
                            <div className="detail-item">
                              <h2>
                                TIPO DE{" "}
                                {reciboDados.classe === "deposito"
                                  ? "DEPÓSITO"
                                  : "RETIRO"}
                              </h2>
                              <p>{reciboDados.tipo}</p>
                            </div>
                          )}

                          {reciboDados.remetente && (
                            <div className="detail-item">
                              <h2>REMITENTE</h2>
                              <p>{reciboDados.remetente}</p>
                            </div>
                          )}

                          {reciboDados.beneficiario && (
                            <div className="detail-item">
                              <h2>BENEFICIARIO</h2>
                              <p>{reciboDados.beneficiario}</p>
                            </div>
                          )}

                          {reciboDados.conta && (
                            <div className="detail-item">
                              <h2>NÚMERO DE CUENTA</h2>
                              <p>{reciboDados.conta}</p>
                            </div>
                          )}

                          {reciboDados.banco && (
                            <div className="detail-item">
                              <h2>BANCO</h2>
                              <p>{reciboDados.banco}</p>
                            </div>
                          )}

                          {reciboDados.documento && (
                            <div className="detail-item">
                              <h2>DOCUMENTO BENEFICIARIO</h2>
                              <p>{reciboDados.documento}</p>
                            </div>
                          )}

                          {reciboDados.pix_alias && (
                            <div className="detail-item">
                              <h2>
                                {reciboDados.moeda_sigla === "BRL"
                                  ? "CLAVE PIX"
                                  : "CBU/CVU/ALIAS"}
                              </h2>
                              <p>{reciboDados.pix_alias}</p>
                            </div>
                          )}

                          {reciboDados.wallet && (
                            <div className="detail-item">
                              <h2>WALLET</h2>
                              <p>{reciboDados.wallet}</p>
                            </div>
                          )}

                          {reciboDados.token && (
                            <div className="detail-item">
                              <h2>TOKEN</h2>
                              <p>{reciboDados.token}</p>
                            </div>
                          )}

                          {reciboDados.rede && (
                            <div className="detail-item">
                              <h2>REDE</h2>
                              <p>{reciboDados.rede}</p>
                            </div>
                          )}

                          {reciboDados.tx_hash && (
                            <div className="detail-item">
                              <h2>HASH DE TRANSACCIÓN</h2>
                              <p style={{ wordBreak: "break-all" }}>
                                {reciboDados.tx_hash}
                              </p>
                            </div>
                          )}

                          {reciboDados.coelsa_id && (
                            <div className="detail-item">
                              <h2>COELSA ID</h2>
                              <p>{reciboDados.coelsa_id}</p>
                            </div>
                          )}
                        </div>

                        {reciboUuid && (
                          <div className="detail-item">
                            <h2>ID DE TRANSACCIÓN NAUTT</h2>
                            <p>{reciboUuid}</p>
                          </div>
                        )}

                        <div className="footer-recibo">
                          <p>
                            Esta transacción es
                            <br />
                            operada por{" "}
                            <strong>
                              {reciboDados.sistema === "koywe"
                                ? `Koywe ${
                                    reciboDados.moeda_sigla === "ARS"
                                      ? "Argentina"
                                      : reciboDados.moeda_sigla === "CLP"
                                      ? "Chile"
                                      : reciboDados.moeda_sigla === "PEN"
                                      ? "Perú"
                                      : reciboDados.moeda_sigla === "COP"
                                      ? "Colombia"
                                      : reciboDados.moeda_sigla === "MXN"
                                      ? "México"
                                      : ""
                                  }`
                                : "Nautt Finance"}
                            </strong>
                          </p>
                        </div>
                      </>
                    )
                  )}
                </div>
                <div className="text-center mt-4">
                  <Link to="/" className="btn btn-light btn-white">
                    <i className="mdi mdi-home-outline" /> Volver
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Recibo);
