import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Alert,
  Button,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FormCrypto from "./FormCrypto";
import FormPix from "./FormPix";
import FormLatam from "./FormLatam";
import FormArs from "./FormArs";
import FormUsd from "./FormUsd";
import FeatherIcon from "feather-icons-react";

import brFlag from "../../assets/images/flags/br.svg"; //brasil
import arFlag from "../../assets/images/flags/ar.svg"; //argentina
import usFlag from "../../assets/images/flags/us.svg"; //estados unidos
import clFlag from "../../assets/images/flags/cl.svg"; //chile
import peFlag from "../../assets/images/flags/pe.svg"; //peru
import mxFlag from "../../assets/images/flags/mx.svg"; //mexico
// import coFlag from "../../assets/images/flags/co.svg"; //colombia
import usdtIcon from "../../assets/images/svg/crypto-icons/usdt.svg"; //Tether

const apiClient = new APIClient();

const depositOptions = [
  { value: "", label: "Seleccione una opción", icon: null },
  { value: "cripto", label: "Crypto (USDT)", icon: usdtIcon },
  { value: "pix", label: "PIX (BRL)", icon: brFlag },
  { value: "ars", label: "Pesos Argentinos (ARS)", icon: arFlag },
  { value: "usd", label: "Dólares (USD)", icon: usFlag },
  { value: "clp", label: "Pesos Chilenos (CLP)", icon: clFlag },
  { value: "mxn", label: "Pesos Mexicanos (MXN)", icon: mxFlag },
  { value: "pen", label: "Soles Peruanos (PEN)", icon: peFlag },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 38,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  option: (base, state) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "8px 12px",
    cursor: "pointer",
    backgroundColor: state && state.isFocused ? "rgba(128, 128, 128, 0.1)" : "transparent"
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 8px",
    alignItems: "center",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const OptionComponent = ({ data, ...props }) => (
  <div {...props.innerProps} style={customStyles.option({}, { isFocused: props.isFocused })}>
    {data.icon && (
      <img
        src={data.icon}
        alt=""
        className="rounded-circle"
        width="20"
        height="20"
        style={{ objectFit: "cover" }}
      />
    )}
    {data.label}
  </div>
);

const SingleValueComponent = ({ data }) => (
  <div style={customStyles.singleValue()}>
    {data.icon && (
      <img
        src={data.icon}
        alt=""
        className="rounded-circle"
        width="20"
        height="20"
        style={{ objectFit: "cover" }}
      />
    )}
    {data.label}
  </div>
);

const ModalNovo = ({ modal, setModal, setRefresh, moedas, tokens }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggle = () => {
    setSelectedOption(null);
    setModal(!modal);
  };

  const limparSelecao = () => {
    setSelectedOption(null);
  };

  const criar = async (data) => {
    setLoading(true);

    const response = await apiClient.post(`/depositos`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      if (data.tipo === "cripto") toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const renderForm = () => {
    if (!selectedOption) {
      return (
        <Alert color="warning" className="text-center">
          Por favor, seleccione una opción de depósito para continuar.
        </Alert>
      );
    }

    switch (selectedOption.value) {
      case "cripto":
        return <FormCrypto tokens={tokens} onSubmit={criar} />;
      case "pix":
        return <FormPix onSubmit={criar} toggle={toggle} />;
      case "ars":
        return <FormArs toggle={toggle} />;
      case "usd":
        return <FormUsd toggle={toggle} />;
      case "clp":
        return <FormLatam moeda={selectedOption.value.toUpperCase()} />;
      case "mxn":
        return <FormLatam moeda={selectedOption.value.toUpperCase()} />;
      case "pen":
        return <FormLatam moeda={selectedOption.value.toUpperCase()} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader className="modal-title" toggle={toggle}>
        Nuevo Depósito
      </ModalHeader>
      <ModalBody className="text-left p-4">
        <Row className="mb-4">
          <Col>
            {!selectedOption ? (
              <Select
                value={selectedOption}
                onChange={(option) => setSelectedOption(option)}
                options={depositOptions}
                styles={customStyles}
                components={{
                  Option: OptionComponent,
                  SingleValue: SingleValueComponent,
                }}
                isSearchable={false}
                placeholder="Seleccione una opción"
                className="form-select"
              />
            ) : (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center flex-grow-1">
                  <img
                    src={selectedOption.icon}
                    alt=""
                    className="rounded-circle me-2"
                    width="20"
                    height="20"
                    style={{ objectFit: "cover" }}
                  />
                  <span>{selectedOption.label}</span>
                </div>
                <Button
                  color="danger"
                  className="btn btn-sm btn-outline-danger ms-2"
                  onClick={limparSelecao}
                  title="Limpiar selección"
                >
                  <FeatherIcon icon="x" size={16} /> Limpiar selección
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {renderForm()}
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
