import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import logoLight from "../../assets/images/logo-light.png";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [lembrar, setLembrar] = useState(false);
  const [needs2FA, setNeeds2FA] = useState(false);
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const inputRefs = useRef([]);

  document.title = "Iniciar sesión | Nautt Finance";

  const esquecerLembrarSenha = useCallback((lembrarSenha, user, pass) => {
    if (!lembrarSenha) {
      localStorage.removeItem("dadosLogin");
    } else {
      const passBase64 = btoa(pass);
      localStorage.setItem(
        "dadosLogin",
        JSON.stringify({ username: user, password: passBase64 })
      );
    }

    setLembrar(lembrarSenha);
  }, []);

  const handleCodeChange = async (index, value) => {
    if (value.length > 1) value = value[0];
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value !== "") {
      // Move to next input
      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      } else {
        // If it's the last digit, submit the form
        const fullCode = newCode.join("");
        if (fullCode.length === 6) {
          await handleSubmit(null, fullCode);
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && verificationCode[index] === "" && index > 0) {
      // Move to previous input and clear it
      const newCode = [...verificationCode];
      newCode[index - 1] = "";
      setVerificationCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e, code2FA = null) => {
    if (e) e.preventDefault();
    setLoading(true);

    const loginData = {
      username,
      password,
      code_2fa: code2FA,
    };

    const response = await apiClient.postLogin(loginData);

    if (response.sucesso) {
      if (!response.dados) {
        setNeeds2FA(true);
        setVerificationCode(["", "", "", "", "", ""]);
        setTimeout(() => inputRefs.current[0]?.focus(), 100);
      } else {
        localStorage.setItem("authUser", JSON.stringify(response.dados));
        localStorage.setItem("lastLogin", Date.now());
        setTimeout(() => {
          window.location.href = "/principal";
        }, 200);
      }
    } else {
      setVerificationCode(["", "", "", "", "", ""]);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }

    setLoading(false);
  };

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      !lembrar &&
      dadosLogin.username !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setUsername(dadosLogin.username);
      setPassword(atob(dadosLogin.password));
      setLembrar(true);
    }
  }, [lembrar]);

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" />
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-success">¡Bienvenido!</h5>
                      <p className="text-muted">
                        {needs2FA
                          ? "Ingrese el código de verificación 2FA"
                          : "Inicia sesión para acceder"}
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      {needs2FA ? (
                        <div className="mb-4">
                          <div className="d-flex justify-content-center gap-2 mb-3">
                            {verificationCode.map((digit, index) => (
                              <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="text"
                                className="form-control text-center fw-bold fs-4"
                                style={{
                                  width: "28px",
                                  height: "45px",
                                  padding: "0.375rem",
                                }}
                                value={digit}
                                onChange={(e) =>
                                  handleCodeChange(index, e.target.value)
                                }
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                maxLength={1}
                                autoFocus={index === 0}
                              />
                            ))}
                          </div>
                          <p className="text-muted text-center">
                            Ingrese el código de su aplicación Google
                            Authenticator
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="mb-3">
                            <Label htmlFor="username" className="form-label">
                              Usuario
                            </Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Ingresa tu usuario"
                              type="text"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                ¿Olvidaste tu contraseña?
                              </Link>
                            </div>
                            <Label
                              className="form-label"
                              htmlFor="password-input"
                            >
                              Contraseña
                            </Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                name="password"
                                value={password}
                                type={showPassword ? "text" : "password"}
                                className="form-control pe-5"
                                placeholder="Ingresa tu contraseña"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                                onClick={() => setShowPassword(!showPassword)}
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                                checked={lembrar}
                                onChange={(e) =>
                                  esquecerLembrarSenha(
                                    e.target.checked,
                                    username,
                                    password
                                  )
                                }
                                disabled={username === "" || password === ""}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Recuérdame
                              </Label>
                            </div>
                          </div>

                          <div className="mt-4">
                            <Button
                              color="success"
                              disabled={loading}
                              className="btn btn-success w-100"
                              onClick={(e) => handleSubmit(e)}
                            >
                              {loading ? (
                                <Spinner size="sm" className="me-2" />
                              ) : null}
                              Iniciar sesión
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>

                {!needs2FA && (
                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      ¿No tienes una cuenta?{" "}
                      <Link
                        to="/kyc-wizard-onboarding"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        {" "}
                        Haz clic aquí
                      </Link>{" "}
                      si eres Persona Física o{" "}
                      <Link
                        to="/kyb-wizard-onboarding"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        {" "}
                        haz clic aquí
                      </Link>{" "}
                      si eres Persona Jurídica.
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
