import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  Badge,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import Select from "react-select";

import SearchOption from "../../Components/Common/SearchOption";
import ModalEditar from "./ModalEditar";
import ModalApagar from "./ModalApagar";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  converterData,
} from "../../Components/FunctionsRepository";

import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const KycVerifications = () => {
  document.title = `Verificaciones KYC | Nautt Finance`;

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [verificacoes, setVerificacoes] = useState([]);
  const [verificacao, setVerificacao] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeVerificacoes, setTotalDeVerificacoes] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const toggleModalEditar = useCallback(
    (seg) => {
      setVerificacao(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalApagar = useCallback(
    (seg) => {
      setVerificacao(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const fetchVerificacoes = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/kyc-onboarding/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setVerificacoes(dados.data);
        setTotalDeVerificacoes(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const limparFiltros = useCallback(() => {
    setTermoBusca("");
    setPaginaAtual(1);
    setLoadTable(true);
    setLoading(true);
  }, []);

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchVerificacoes(itensPorPagina, paginaAtual, termoBusca);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Verificaciones de KYC" pageTitle="Nautt" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Elementos por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Verificaciones
                  </h4>
                  {/* <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => toggleModalNovo()}
                    >
                      Nueva Verificación
                    </button>
                  </div> */}
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID #</th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Documento Fiscal</th>
                              <th scope="col">País</th>
                              <th scope="col">Origen</th>
                              <th scope="col">Estado</th>
                              <th scope="col">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {verificacoes.length !== 0 &&
                              verificacoes.map((verificacao, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {verificacao.id}
                                      <br />
                                      <small className="text-muted">
                                        {converterData(verificacao.created_at)}
                                      </small>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">{verificacao.nome}</p>
                                    </td>
                                    <td>{verificacao.documento}</td>
                                    <td>{verificacao.country_code}</td>
                                    <td>
                                      {verificacao.link_pagamento ? (
                                        <Badge
                                          color="success"
                                          className="badge bg-success-subtle text-success"
                                        >
                                          Link Pagamento
                                        </Badge>
                                      ) : (verificacao.kyb_id || 0) > 0 ? (
                                        <>
                                          <Badge
                                            color="primary"
                                            className="badge bg-primary-subtle text-primary"
                                          >
                                            KYB
                                          </Badge>
                                          <br />
                                          <small className="text-muted">
                                            ({verificacao.kyb?.nome_fantasia})
                                          </small>
                                        </>
                                      ) : (
                                        <Badge
                                          color="secondary"
                                          className="badge bg-secondary-subtle text-secondary"
                                        >
                                          Onboarding
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      {verificacao.status === "verificado" ? (
                                        <Badge color="success">
                                          Verificado
                                        </Badge>
                                      ) : verificacao.status === "negado" ? (
                                        <Badge color="danger">Rechazado</Badge>
                                      ) : (
                                        <Badge color="info">Pendiente</Badge>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        className="text-success"
                                        title="Editar"
                                        onClick={() =>
                                          toggleModalEditar(verificacao)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="edit"
                                          width="14"
                                          className="me-3"
                                        />
                                      </Link>
                                      {verificacao?.kyc_code !== null && (
                                        <Link
                                          to={`/kyc-wizard/${verificacao?.kyc_code}`}
                                          className="text-info"
                                          title="Ir a la verificación"
                                          target="_blank"
                                        >
                                          <FeatherIcon
                                            icon="external-link"
                                            width="14"
                                            className="me-3"
                                          />
                                        </Link>
                                      )}
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        title="Eliminar"
                                        onClick={() =>
                                          toggleModalApagar(verificacao)
                                        }
                                      >
                                        <FeatherIcon
                                          icon="trash-2"
                                          width="14"
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && verificacoes.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Ninguna verificación encontrada
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeVerificacoes
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeVerificacoes
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeVerificacoes}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeVerificacoes}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    verificacao={verificacao}
                    setRefresh={setRefresh}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    verificacao={verificacao}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default KycVerifications;
